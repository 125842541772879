import VueRouter from 'vue-router';
import store from '@/store/index';

function memberGoto(router: VueRouter, url: string) {
  if (store.user.name) {
    router.push(url);
    return;
  }
  store.signInGoTo = url;
  store.signInVisible = true;
}

export default memberGoto;
